import { axiosInstance } from "../axios";

export const getRoleAccess = async (data) => {
  const roleID = localStorage.getItem("role_id");

  try {
    if (roleID) {
      const response = await axiosInstance.get(`/role-permission/${roleID}`);

      // Check if response is valid and has data
      const roleData = response?.data?.data?.[0];
      if (response?.status === 200) {
        return roleData;
      }
    }
  } catch (error) {
    console.error("Error fetching role access:", error);
  }
};

