import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  AuditOutlined,
  ContactsOutlined,
  DeploymentUnitOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
  UserOutlined,
  BankOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { pathname } from "../../pathname";
import logoutIcon from "../../Assests/Images/logout.svg";
import CloseSIdebar from "../../Assests/Images/CloseSideBar.svg";
import OpenSidebar from "../../Assests/Images/OpenSIdebar.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsSideBarOpen } from "../../redux/Slices/SIdebar/SidbarVarSlice";
import { resetState } from "../../redux/Slices/RBAC/roleBasedActionSlice";
import briefCase from "../../Assests/Images/briefcase.svg";
import company from "../../Assests/Images/company.svg";

const SiderBar = () => {
  const isSideBarOpen = useSelector(
    (state) => state.SidebarVariables.isSideBarOpen
  );
  const roleData = useSelector((state) => state.RoleBasedActions.role_access);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(() => {
    // Get selected key from localStorage or set default to "0"
    return localStorage.getItem("selectedKey") || "0";
  });
  const [items, setItems] = useState([
    getItem(<Link to="/">Dashboard</Link>, "0", <AppstoreOutlined />, null),
  ]);

  const definedModules = {
    mobilizer_management: getItem(
      <Link to={pathname.MOBILIZER_MANAGEMENT}>Mobilizer Management</Link>,
      "5",
      <UserOutlined />,
      null
    ),
    center_management: getItem(
      <Link to={pathname.CENTER_MANAGEMENT}>Center Management</Link>,
      "6",
      <BankOutlined />,
      null
    ),
    job_management: getItem(
      <Link to={pathname.JOB_MANAGEMENT}>Job Management</Link>,
      "7",
      <ContactsOutlined />,
      null
    ),
    categories: getItem("Categories", "sub1", <DeploymentUnitOutlined />, [
      getItem(
        <Link to={pathname.JOB_CATEGORY}>Job Category</Link>,
        "10",
        <img src={briefCase} alt="Job" className="h-[17px] w-[17px]" />,
        null
      ),
      getItem(
        <Link to={pathname.COMPANY_CATEGORY}>Company Category</Link>,
        "13",
        <img
          src={company}
          alt="Company"
          className="filter grayscale h-[17px] w-[17px]"
        />,

        null
      ),
    ]),
    referral_management: getItem(
      <Link to={pathname.REFERRAL_MANAGEMENT}>Referral Management</Link>,
      "15",
      <AuditOutlined />,
      null
    ),
    query_management: getItem(
      <Link to={pathname.QUERRY_MANAGEMENT}>Query Management</Link>,
      "17",
      <ExclamationCircleOutlined />,
      null
    ),
    // coporate_onboarding: getItem(
    //   <Link to={pathname.COPORATE_OBOARDING}>Coporate Onboarding</Link>,
    //   "178",
    //   <ExclamationCircleOutlined />,
    //   null
    // ),

    settings: getItem("Settings", "sub2", <SettingOutlined />, [
      getItem(<Link to={pathname.SUB_ADMINS}>Sub-admins</Link>, "19", null),
    ]),
    faq: getItem(
      <Link to={pathname.TRAINING_AND_FAQ}>Training & FAQs</Link>,
      "22",
      <QuestionCircleOutlined />,
      null
    ),
    incentive_payouts: getItem(
      <Link to={pathname.INCENTIVE_PAYOUTS}>Incentive Payouts</Link>,
      "18",
      <MoneyCollectOutlined />,
      null
    ),
    bulk_notification: getItem(
      <Link to={pathname.BULK_NOTIFICATION}>Bulk Notification</Link>,
      "1099",
      <NotificationOutlined />,
      null
    ),
  };
 

  const listOfMenu = [
    "mobilizer_management",
    "center_management",
    "job_management",
    "categories",
    "referral_management",
    "incentive_payouts",
    "query_management",
    "bulk_notification",
    "settings",
    "faq",
    // "coporate_onboarding"
  ];

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  useEffect(() => {
    if (roleData && Object.keys(roleData)?.length !== 0) {
      const permissions = new Set();
      ["READ", "WRITE", "DELETE", "ALL"].forEach((key) => {
        roleData[key]?.forEach((permission) => permissions.add(permission));
      });
      permissions.add("coporate_onboarding")
      const permittedModules = Array.from(permissions);
      const mappedItems = [];
      listOfMenu.map((menu, index) => {
        if (permittedModules.includes(menu)) {
          mappedItems.push({
            module: definedModules[menu],
            key: `item_${index}`,
          });
        }
      });
      setItems([...items, ...mappedItems.map((item) => item.module)]);
    }
  }, [roleData]);

  useEffect(() => {
    let currentPath = location.pathname;
    if (location.pathname.split("/").includes("job-management")) {
      currentPath = "/job-management";
    } else if (location.pathname.split("/").includes("category-job")) {
      currentPath = "/category-job";
    } else if (location.pathname.split("/").includes("referral-management")) {
      currentPath = "/referral-management";
    } else if (location.pathname.split("/").includes("training-and-faq")) {
      currentPath = "/training-and-faq";
    } else if (location.pathname.split("/").includes("mobilizer-management")) {
      currentPath = "/mobilizer-management";
    }else if (location.pathname.split("/").includes("bulk-notification")) {
      currentPath = "/bulk-notification";}
    const matchedKey = Object.keys(pathname).findIndex(
      (key) => pathname[key] === currentPath
    );

    if (matchedKey !== -1 && matchedKey !== 11 && matchedKey !== 14) {
      const matchedKeyString = matchedKey.toString();
      setSelectedKey(matchedKeyString);
      localStorage.setItem("selectedKey", matchedKeyString);
    } else if ([10, 11].includes(matchedKey)) {
      setSelectedKey("10"); 
      localStorage.setItem("selectedKey", "10");
    } else if ([13, 14].includes(matchedKey)) {
      setSelectedKey("13"); 
      localStorage.setItem("selectedKey", "13");
    } else if ([19].includes(matchedKey)) {
      setSelectedKey("18"); 
      localStorage.setItem("selectedKey", "18");
    } else {
      setSelectedKey("-1");
      localStorage.setItem("selectedKey", "-1");
    }
  }, [location.pathname, selectedKey]);

  return (
    <div className="flex flex-col justify-between h-full py-4 border transition-all overflow-scroll">
      <div>
        <div>
          <div
            className={`flex  ${
              isSideBarOpen ? "px-6 justify-between" : "  justify-center"
            } h-[10vh] items-start`}
          >
            {isSideBarOpen ? (
              <>
                {" "}
                <p className="font-semibold text-[1.5rem]">KADAM</p>
                <img
                  src={CloseSIdebar}
                  alt="Collapse"
                  className="cursor-pointer h-5 mt-2"
                  onClick={() => dispatch(setIsSideBarOpen(false))}
                />
              </>
            ) : (
              <>
                <img
                  src={OpenSidebar}
                  alt="Open"
                  className="cursor-pointer h-6"
                  onClick={() => dispatch(setIsSideBarOpen(true))}
                />
              </>
            )}
          </div>
        </div>

        <Menu
          style={{
            width: "100%",
          }}
          defaultSelectedKeys={[`${selectedKey}`]}
          mode="inline"
          items={items.map((item, index) => ({
            ...item,
            key: `${item.key}`,
          }))} // Ensure unique keys
          inlineCollapsed={!isSideBarOpen}
        />
      </div>
      <div
        className={`flex gap-2 mb-4 ${
          isSideBarOpen ? "px-6" : "px-2 justify-center items-center"
        } cursor-pointer`}
        onClick={() => {
          dispatch(resetState());
          localStorage.clear();
          navigate(pathname.LOGIN);
        }}
      >
        <img src={logoutIcon} alt="" />
        {isSideBarOpen && <p className="text-[14px] text-[#4D4C4C]">Logout</p>}
      </div>
    </div>
  );
};

export default SiderBar;
